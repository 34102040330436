.app-icon{
    margin: 0 0.1em;
    position: relative;
}

.app-icon-dimmed{
    //color: #a0a0a0;
    opacity: 50%;
}

.app-icon-text{
    display:inline;

}

.app-bi-icon{
    font-family:"bootstrap-icons";
    margin: 0 0.1em;
    line-height: inherit;
    position: relative;
    display: inline-flex;
    height: 1.5em;
    aspect-ratio: 1;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.app-icon-with-add:after{
    font-family: 'bootstrap-icons';
    content:'\F4FA';    
    position: absolute;
    right: -0.2rem;
    bottom: -0.2rem;
    font-size: 0.7rem;
    color: green;    
    background-color: white;
    border-radius: 0.3rem;
}

.icon-border{
    border:1px solid #000080;
    border-radius: 3px;
}

.icon-light{
    color: #ffffff80;
    border-color: #ffffff80;
}

.app-icon-set{
    display:inline;
}

.app-icon-search::before{
    content:'\F52A';
}

.app-icon-edit::before{
    content:'\F4CA'
}

.app-icon-chat::before{
    content:'\F24D';
}

.app-icon-menu::before{
    content:'\F479';
}

.app-icon-home::before{
    content:'\F425';
}

.app-icon-join-meeting::before{
    content:'\F4F3';
}

.app-icon-leave-meeting::before{
    content:'\F591';
}

.app-icon-mike-active:before{
    content:'\F490';
}

.app-icon-mike-inactive::before{
    content:'\F48F';
}

.app-icon-audio-output:before{
    content: '\F611';
}

.app-icon-camera::before{
    content:'\F21F';
}

.app-icon-editPen{
    background-image: url(./editicon.png);
    background-size: cover;
    width: 32px;
    height:32px;
    display: inline-block;
}

.app-icon-save:before{
    content: '\F7D8';
}

.app-icon-cancel:before{
    content:'\F629';
    color:red;
}

.app-icon-accept::before{
    content:'\F26D';
    color:green;
}

.app-icon-circleUnchecked:before{
    content:'\F28A';
    font-size: smaller;
}

.app-icon-circleChecked:before{
    content:'\F270';
}

.app-icon-boxUnchecked:before{
    content:'\F584';
    font-size: smaller;
}

.app-icon-boxChecked:before{
    content:'\F271';
}

.app-icon-copy:before{
    content:'\F733';
}

.app-icon-properties:before{
    content:'\F3E2';
}

.app-icon-plus::before{
    content:'\F4F9';
}

.app-icon-minus::before{
    content:'\F2E5';
}

.app-icon-delete::before{
    content: '\F5DE';
}

.app-icon-menu{
    border: 1px solid #a0a0a0;
    border-radius: 3px;
}

.app-icon-borders{
    border: 1px solid #a0a0a0;
    padding: 2px;
    color: #606060;
    border-radius: 3px;
}

.app-icon-login:before{
    content:'\f2f6';
}


.app-icon-logout:before{
    content:'\f2f5';
}


.app-icon-diamond:before{
    content:'\f219';
}

.app-icon-bar:before{
    content:'\25ac';
}

.app-icon-close:before{
    content: '\F629';
}



.app-icon-circle:before{
    content: '\F287';
}

