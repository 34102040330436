
.campaignTile{

    .playerList{
        display:flex;
        flex-wrap: wrap;
        gap: 10px;

        .playerUsername{
            background-color: #ffffff90;
            border-radius: 3px;
            padding: 3px;
        }
    }
}

.currentCampaignIcon{
    font-family: "bootstrap-icons" ;
    font-size: 16pt;
    color: #ffff00b0;
    position: absolute;
    right: 3px;
    top: 0;
}

.currentCampaignIcon::before{
    content:'\F586';
}
