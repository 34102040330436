.handoutContent{
    width: 840px;
    margin: 0 auto;
}

.handoutList{
    display:flex;
    flex-wrap: wrap;
}

.handoutDetail{
}

.availableTo{
    display:flex;
    flex-direction: column;

    .handoutPlayerItem{
        padding:5px;
        border: 1px solid #00000080;
        border-radius: 3px;
        cursor: pointer;

        p{
            margin: 0;
            padding:0
        }
    }

    .handoutPlayerItem.isAvailable{
        border: 2px solid blue;
    }

    .availableToList{
        display:flex;
        gap: 10px;
    }
}

.handoutItem{
    margin: 5px;
    cursor: pointer;

    .handoutImage{
        width: 120px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        display:flex    
    }

    .handoutImage canvas{
        position: absolute;
    }

    .handoutDescription{
        text-align: center;
        z-index: 1000;
    }

    .handoutDescription p{
        background-color: #ffffffa0;
        text-align: center;
        width: fit-content;
        border-radius: 3px;
        border: 1px solid #e0e0e0;
        padding: 5px;
        display:inline-block;
    }
}

.handoutDetail{

    .handoutHeader{
        display: flex;
        justify-content: center;
        gap: 10px;    
    }

    .handoutData{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img, .handoutText{
            max-width: 400px;
        }
    }
}



.handoutDetail{
    .formField{
        display:flex;
        flex-direction: column;
    }

    textarea{
        width: 400px;
        height: 200px;
    }
}

.handoutForm{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #00000080;
    border-radius: 10px;
    padding: 10px;    

    .handoutBasics, .handoutText{
        display:flex;
        gap:10px;
    }
}