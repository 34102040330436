@import './variables.scss';

html,body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

html,body,div,p,th,td,input,select,textarea{
  font-size:12px;
  color: #{$font-color};
}

table{
  width:100%;
}

td{
  border-bottom: 1px solid #a0a0a0;
}

th{
  text-align: left;
  border-bottom: 1px solid black;
}

#root{
  position:absolute;
  top:0;
  left:0;
  /* width: calc(100% - 360px); */
  width: 100%;
  height:100%;
}

.clickable{
  cursor:pointer;
}
/*
aside{
  position:absolute;
  right: 0;
  top:0;
  width: 360px;
  height:100%;
  display:none;
}
*/
.hidden{
  display:none !important;
}

input[type="text"],input:not([type]),input[type="password"],input[type="number"]{
  outline: none;
  background: #ffffff80;
  border: none;
  border-bottom: 1px solid #00000080;        
}

input[type="number"]{
  width: 60px;
}

