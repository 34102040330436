.characterReputation{
    display:flex;
    justify-content: space-between;
}

.reputationPair{
    width: 100px;
    display: flex;
    border: 1px solid #00000080;
    border-radius: 5px;
    margin: 0 5px;

    .reputationItem{
        width:48px;

        img {
            width: 40px;
        }

        p {
            text-align: center;
        }

        .modIcons{
            text-align: center;
        }
    }

    .reputationLeft{
        text-align: right;

        img {
            margin: 0 0 0 auto;
        }
    }

    .reputationRight {
        
        img{
            margin:0;
            transform: scaleX(-1);
        }
    }




}