.itemContainer-worn h3, .locationValue-worn{
    background-image: url(./assets/worn.svg);
}
.itemContainer-beltpouch h3, .locationValue-beltpouch{
    background-image: url(./assets/beltpouch.svg);
}

.itemContainer-onperson h3, .locationValue-onperson{
    background-image: url(./assets/onperson.svg);
}

.itemContainer-packings h3, .locationValue-packings{
    background-image: url(./assets/packings.svg);
}

.itemContainer-extra h3, .locationValue-extra{
    background-image: url(./assets/extra.svg);
}

.locationValue-trash{
    background-image: url(./assets/trash.svg);
    margin-left: 20px;
}

.itemContainer{
    width: 180px;
    
    h3{
        background-repeat: no-repeat;
        background-position: right center;

    }

    p{
        margin:0;
    }

    .app-icon-minus{
        color:#000000a0;
        font-size:10px;
    }

    .itemHasFocus{
        border: 1px solid #00000080;
        border-radius: 3px;
        padding: 3px;
        display: flex;
        flex-direction: column;   
        margin: 3px 0; 
    }

    .inputNoFocus{
        cursor: pointer;
        background: none;
        border-bottom: 1px dotted #00000080;
    }

    .itemNoEdit{
        display:flex;
        gap: 5px;

        .amountCell{
            display:flex;

        }
    }

    .itemEditRow{

        input{
            width: 172px;
        }

        input.shortenName{
            width: 100px;
        }

        input.itemPriceAmount{
            width:40px;
        }

        label{
            display: inline-block;
            width: 120px
        }
    }

    .valueItem{
        display:flex;
        gap: 5px;

        input{
            width:100px;
        }

        input.itemPrice{
            width:40px;
        }
    }

    .valueItem.valueItemEdit{
        display:flex;
        justify-content: space-between;
    }

    .valueItemName{
        flex:1;
    }
}

.itemTypeSelector{
    display:flex;
}

.itemType{
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 20px;
    height:20px;
    border: 1px solid #00000080;
    border-radius: 3px;
    opacity: 0.5;
}

.itemTypeSelected{
    opacity: 1;
}

.itemType-Armor{
    background-image: url(./assets/armor.svg);
}
.itemType-Arrows{
    background-image: url(./assets/arrows.svg);
}
.itemType-Bow{
    background-image: url(./assets/bow.svg);
}
.itemType-Crossbow{
    background-image: url(./assets/crosbow.svg);
}
.itemType-Other{
    background-image: url(./assets/other.svg);
}
.itemType-Shield{
    background-image: url(./assets/shield.svg);
}
.itemType-Valuable{
    background-image: url(./assets/valuable.svg);
}
.itemType-Weapon{
    background-image: url(./assets/weapon.svg);
}

.acSelector,.damageSelector,.bonusSelector, .locationSelector{
    display:flex;
    margin: 3px 0;
    gap: 20px;
    align-items: center;

    .acValues, .damageValues, .bonusValues, .locationValues{
        display:flex;
        gap: 3px;
        margin:0;

        .acValue,.damageValue, .bonusValue,.locationValue{
            display:flex;
            width:20px;
            height:20px;
            opacity: 0.5;
            border: 1px solid #00000080;
            border-radius: 3px;
            align-items: center;
            justify-content: center
        }

        .acValueSelected,.damageValueSelected,.bonusValueSelected,.locationValueSelected{
            opacity: 1;
        }
    }

    .damageValue,.locationValue{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .damageValueD4{
        background-image: url(../../dice/assets/d4.svg);
    }
    .damageValueD6{
        background-image: url(../../dice/assets/d6.svg);
    }
    .damageValueD8{
        background-image: url(../../dice/assets/d8.svg);
    }
    .damageValueD10{
        background-image: url(../../dice/assets/d10.svg);
    }
}

.locationSelector{
    flex-direction: column;
    gap: 0;    
}