
button.mapToolButton{
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 20px;
    position:relative;
}

.mapControls{
    display: flex;
    align-items: flex-end;
    gap: 20px;
    
    div,p{
        display:flex;
        align-items: center;
        margin:0;
        padding:0;
        flex-direction: column;
        gap:0;
    }

    .toolGroup{
        display:flex;
        flex-direction: row;
        gap: 3px;
    }
}



.mapToolsPopup{
    position:absolute;
    display:flex;
    gap: 20px;
    z-index: 1000;

    .playerNote{
        display: flex;
        flex-direction: column;        
    }


    button{
        width: 28px;
        height: 28px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        background-size: 20px;
        position:relative;
    }

    .buttonDrawArea.selectedTool, .buttonDrawLine.selectedTool{
        opacity:1;
    }

}
    
.buttonDrawArea{
    background-image: url(./images/draw-area.svg);
    opacity: 0.5;
}

.buttonDrawLine{
    background-image: url(./images/draw-line.svg);
    opacity: 0.5;
}

.buttonClearClicks{
    background-image: url(./images/clear-clicks.svg);
}

.buttonNewMap{
    background-image: url(./images/new-map.svg);
}

.buttonSaveMap{
    background-image: url('./images/save-map.svg');
}

.buttonSetSelected{
    background-image: url('./images/set-selected.svg');
}

.buttonMapProperties{
    background-image:url(./images/map-properties.svg)
}

.buttonOpenMap{
    background-image: url(./images/open-map.svg);
}

.buttonAddNote{
    background-image: url(./images/add-note.svg);
}

.buttonAddPointer{
    background-image: url(./images/add-pointer.svg)
}

.buttonRemovePointer{
    background-image: url(./images/remove-pointer.svg)
}

.buttonCancel{
    background-image: url(./images/tool-cancel.svg);
}

.buttonShareMap{
    background-image: url(./images/share.svg);
}

.buttonOpenDoor{
    background-image: url(./images/opendoor.svg);
}

.buttonCloseDoor{
    background-image: url(./images/closedoor.svg);
}

.buttonEditMode{
    background-image: url(./images/editmode.svg);
}

.buttonPlayMode{
    background-image: url(./images/playmode.svg);
}

.buttonAvatarsShown{
    background-image:url(./images/avatarsshown.svg)
}

.buttonAvatarsHidden{
    background-image:url(./images/avatarshidden.svg)
}

.buttonShowNotesMarkers{
    background-image: url(./images/show-notes-markers.svg);
}

.buttonShowNotesText{
    background-image: url(./images/show-notes-text.svg);
}