.npcsMain{
    display:block;

    .characterEditor{
        position: absolute;
        top: 200px;
        left: 200px;
        background-color: #f0f0f0f0;
    }
}

.npcList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.npcButton{
    display:flex;
    border: 1px solid #00000080;
    border-radius: 5px;
    padding: 5px;
    gap: 5px;
    position: relative;
    width: 160px;

    .npcDescription{
        display: flex;
        flex-direction: column;
        width: 100%;

        h5{
            display: flex;
            justify-content: space-between;

            .numbers{
                background-color: #f0f0f0;
                border: 1px solid #00000020;
                border-radius: 5px;
                font-size: 9pt;
                padding: 0 3px;
                font-family: sans-serif;
                display: inline-block;
                width: 32px;
                text-align: center;            }
        }

        .npcDetail{
            display: flex;
            gap: 5px;
            min-height: 48px;
            width: 100%;
            justify-content: space-between;
            
            p{
                margin:0;
                font-size: 8pt;
            }

            >img{
                width: 32px;
            }
        }

        .defence{
            width: 28px;
            display:flex;
            background-image: url(./shield.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            align-items: center;

            p{
                margin: 0 auto;
                font-size: 11pt;
            }
        }

        .initiative{
            width: 28px;
            display: flex;
            background-image: url(./initiative.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            align-items: center;

            p{
                margin: 0 auto;
                font-size: 11pt;
            }

        }

    
    }


}