.realmMasterForPlayers{
    width: 420px;
    margin: 0 auto;

}

.realmMasterTools{
    display:flex;
    gap: 10px;
    flex-wrap: wrap;

    .formField{
        display: flex;

        label{
            width: 120px;
        }
    }
}
