.campaignNavi{
    display:flex;
    justify-content: center;

    .mapArea,.handoutItem,.journalTile, .naviButton{
        background-color: #f0f0f0;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid black;
        background-repeat: no-repeat;
        background-size: cover;
        width: 80px;
        height: 80px;
        position: relative;
        margin: 3px;
        background-position: center;

        .title{
            position:absolute;
            top:0;
            left:3px;
            font-family: 'Quintessential';
        }

        .subTitle{
            position:absolute;
            bottom:0;
            left:3px;
            margin:0;
            font-size: 8pt;
        }

        img{
            position: absolute;
            width: 36px;
            bottom:3px;
            right:3px;
        }
    }

    .naviButton{
        .chatIcon{
            position: absolute;
            top: 0;
            right: -3px;
            font-size: 28px;
            color: #006020;
            background-color: #ffffff80;
            border-radius: 3px;
        }
    }

    .videoButton{
        overflow: hidden;

        video{
            object-fit: cover;
            width: 100px;
            height: 100px;
            margin: -50px -50px;
            position: absolute;
            left: 50%;
            top: 50%;        }
    }

    .naviButton.shareButton{
        background-size: 60%;
        background-position: center;
    }

    .naviButton.naviJournal, .naviButton.naviEncounter{
        background-size: 95%;
        background-position: center bottom ;

    }

    .journalTile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        p {
            cursor:pointer;
            border: 1px solid #00000080;
            border-radius: 3px;
            padding: 5px;
        }
    }
}

.campaignsPage{
    display:flex;
    gap: 10px;
    flex-wrap: wrap;
}

.shareView {
    .handoutDetail{
        max-width: 800px;
        margin: 0 auto;
    }  
    
    .mapEditor{
        /*max-width: 840px;*/
        margin: 0 auto;

        .mapHolder{
            margin: 0 auto;
        }
    }

    .sharedImage{
        text-align: center;

        img{
            max-width: 800px;
        }
    }
}

.campaign{

    .playerList{
        display:flex;
        flex-wrap: wrap;
        gap: 10px;

        .playerUsername{
            background-color: #ffffff90;
            border-radius: 3px;
            padding: 3px;
        }
    }

/*

    .campaignNavi{
        position:absolute;
        height: 92px;

    }

 

    .campaignContent{
        position:absolute;
        top: 92px;
        bottom: 30px;
        left:0;
        right: 0;
    }

    .characterSheet{
        position:absolute;
        top:92px;
        bottom: 30px;
    }

    */
}

