.playerInfo{
    display: flex;
    flex-direction: column;
    gap: 5px;

    h5{
        margin-bottom: 5px;
    }
    background-image: url(./assets/profile.svg);
    background-repeat: no-repeat;
    background-position: right -80px center;
    background-size: contain;}

.playerUsername{
    /*border: 1px solid #00000080; */
    border-radius: 3px;
    padding: 5px;
}

.playerPassword{
    /*border: 1px solid #00000080;*/
    border-radius: 3px;
    padding: 5px;

    .passwordFields{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .passwordField{
        
        label{
            display: inline-block;
            width: 100px;
        }
    }
}

.playerEmail{
    border-radius: 3px;
    padding: 5px;
}