.journalContent{
    width: 1024px;
    margin: 0 auto;

    .journalTop{
        width: 1024px;
        //height: 162px;
        //background-image: url(./journalscrolltop.png);
        background-repeat: no-repeat;
        background-position: top center;
        //text-align: center;
        display: flex;
        justify-content: center;
        gap: 10px
    }

    .journalBottom{
        width: 1024px;
        height: 163px;
        //background-image: url(./journalscrollbottom.png);
        background-repeat: no-repeat;
        background-position: top center;
    }

    .journalItem::before{
        content: ' ';
        background-image: url(../assets/devider-1.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 800px 16px;
        width: 1024px;
        height: 16px;
        opacity: 30%;        
        position: absolute;        
    }

    .journalItem{
        width: 1024px;
        padding: 10px 0;
        background-repeat: no-repeat;
        background-position: top center;

        .journalItemContent {
            width: 600px;
            margin: 20px auto 10px auto;
            display: flex;
            justify-content: space-between;
        }

        .journalItemText h5{
            font-size: 14pt;
        }

        .journalText p{
            font-family: Quintessential, 'Times New Roman', Times, serif;   
            font-size: 12pt;         
        }
    }

    .journalForm{
        input {
            width: 120px;
        }

        textarea{
            width: 240px;
            height: 200px;
        }

        .formButtons{
            text-align: center;
        }
    }

}