.mapsRoute{
    /*width: 840px;*/
    margin: 0 40px;
}

.mapSelectorContent{
    display:flex;
    gap: 20px;

    .newMapOptions{
        display: flex;
        flex-direction: column;
        width: 320px;
    }
}

.newMapButton{
    width: 120px;
    height:120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #00000080;
    border-radius: 5px;
}

.mapButton{
    width:120px;
    height:120px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;

    canvas{
        position:absolute;
    }

    .mapName{
        text-align: center;
        z-index: 1000;

        p{
            margin:0;
            padding: 5px;
            background-color: #ffffffa0;
            border-radius: 3px;
        }
    }
}

.mapSelectorList{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}