@import '../variables.scss';

.characterEditor{
    border: 1px solid #00000080;
    width: 340px;
    padding: 5px;
    border-radius: 5px;
    background-color: #ffffff80;
    height: fit-content;
}

.editorRow{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.npcAttackRow{
    display:flex;
}

.editorRow.nameRow{
    grid-template-columns: 10fr 5fr 5fr;

    input{
        width:90%;
        background: none;
        border: none;
    }
}

.editorRow.extraRow{
    display: flex;
    gap: 20px;

    .itemsCell{
        display: flex;
        flex-direction: column;
        flex:1;
    }

    .npcAvatar{

        img{
            width: 36px;
        }
    }
}

.editorRow.damageRow{
    display: flex;
    flex-direction: column;

    p{
        display: flex;

        .opponentNumber{
            background-color: #f0f0f0;
            border: 1px solid #00000020;
            border-radius: 5px;
            font-size: 9pt;
            padding: 0 3px;
            font-family: sans-serif;
        }

    }
}


.editorCell{
    //width:45px;
    font-size: 10px;
    position:relative;
    border:1px solid #a0a0a0;

    .fightValue{
        display: flex;
        justify-content: space-between;
    }

    input{
        width:100%;
        border:none;
        outline: none;
        text-align: center;
    }
}

.editorCell.reputationCell span{
    font-size: 8px;
}

.rankInput{
    width: 100%;
    position: relative;

    .rankInputCurrent{
        width: 100%;
        display:flex;

        span {
            padding: 0 1px;
        }

        .rankInputValue{
            flex-grow: 1;
            text-align: right;
        }
    }
    
    .rankInputOptions{
        position: absolute;
        /*margin-top:-40px;*/
        background-color: white;
        border: 1px solid #{$border-color};
        z-index:100;
        width: 40px;
        text-align: center;
        cursor:pointer;

        p{
            margin: 2px 0;
            cursor:pointer;
        }

        p:hover{
            background-color: #e0e0e0;
        }
    }
}

.skillvalue{
    text-align: center;
}

