.imageList{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .imageItem{
        position: relative;

        img{
            height:240px
        }

        .imageOptionsHolder{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;

            .imageOptions{
                text-align: center;

                p{
                    background-color: #ffffff80;
                    padding:5px;
                    border-radius: 5px;
                }
            }
        }
    }
}