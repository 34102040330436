.characterList{
    display: flex;
    flex-direction: column;
    gap:4px;
    min-width: 100px;

    .characters{
        display: flex;
        flex-direction: column;
        gap:2px;
    }

    .characterButton{
        border: 1px solid #00000080;
        border-radius: 3px;
        gap:5px;
        justify-content: space-between;
        width:92px;
        
        .avatarImage img{
            width: 32px;
        }

        >div{
            display:flex;
            flex-direction: column;
        }

        .values{
            align-items: flex-end;
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
        }

        .defence, .initiative{
            width: 28px;
            height:28px;
            display: flex;
            background-image: url(../../master/shield.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            align-items: center;
            
            p{
                margin: 0 auto;
                font-size: 11pt;                
            }
        }

        .initiative{
            width:38px;
            height:38px;
            background-image: url(../../master/initiative.svg);
        }

    }

    .characterListContent{
        display:flex;
        gap: 10px;
    }

    .selectedCharacter {
        border: 1px solid #00000080;
        border-radius: 5px;
        padding: 5px;
        width: 240px;

        .selectedCharacterNavi{
            display:flex;
            gap: 12px;
            align-items: flex-end;
        }

        .ownSpells{
            width: 220px;
            display: flex;
            flex-direction: column;
        }

        .spellContainer{
            width: auto;

            .spellItem{
                width: auto;
            }
        }  
    }
}

.characterListWide{
    min-width:360px;
}