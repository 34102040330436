.welcomePage{
    width: 100%;

    .welcomeQuote p{
        font-family: Quintessential, 'Times New Roman', Times, serif;
        font-size: 11pt;        
    }

    .welcomeContent{
        width: 420px;
        margin: 80px auto;
        text-align: center;
        border-radius: 10px;
        background-color: #ffffff50;
        padding: 20px;

        .userManagement{
            margin: 0 auto;
        
            .devider{
                width: 90%;
                height: 10px;
                opacity: 30%;    
            }

            >div{
                margin: 10px 0
            }

            a{
                color: #208020;
                font-weight: bold;
            }
            
        }

        .loginInput{
            text-align: center;
            
            label{
                display:block;
                font-size: 8pt;
            }

            input{
                width:260px; 
                text-align: center;
            }
        }
    }
}
    
