.selectedItems{
    display: flex;
    flex-wrap: wrap;
}

.availableItems{
    display:flex;
    flex-wrap: wrap;
}

.characterWizardItemsNavi{
    display:flex;
    gap: 10px;
    font-size:12pt;
    margin:10px 0;
    border-bottom: 1px solid #20202080;
}

.itemRow{
    display:flex;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #404040;
    border-radius: 5px;;
    flex-direction: column;
    margin: 5px;

    .itemName{
        position:relative;
        display:flex;
        margin:0;
    }

    .itemDescription{
        font-size: 10px;
        margin: 0;
        span{
            display:block;
        }
    }

    .itemPrice{
        margin-left: 10px;
    }

    .itemButton{
        display: inline-block;
        position:absolute;
        border:#404040;
        padding: 0;
        top:-8px;
        left: -8px;
    }

    .itemButtonAdd::after{
        font-family: bootstrap-icons;
        content:'\F19C';
    }

    .itemButtonDelete::after{
        font-family: bootstrap-icons;
        content:'\F2E5';
    }
}

.wizardSkillDevelopment{
    display:flex;
    gap: 20px;
}

.skillsContainer, .hdAndFight{
    display:flex;
    flex-wrap: wrap;

    .skillRow{
        margin: 5px 10px;
        border: 1px solid #808080;
        padding: 3px;
        width: 140px;
        display: flex;
        border-radius: 3px;
        cursor: pointer;
        justify-content: space-between;

        .wizardSkillName{
            display:block;
            font-size: 11pt;
            margin-bottom:3px;
        }

        .skillValue{
            width:24px;
            font-size: 16pt;
            margin:0
        }

        .skillValuesFight{
            width: 80px;
            margin:0;

            p{
                margin:0;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .skillRow.spellCaster{
        border: 1px solid red;
    }

}
/*
#diceHolder{
    position: absolute;
    bottom: 0;   
         
    pointer-events:none;

    #rankTestDice{
        pointer-events: none;
    }
 
}
*/
.spellContainer{
    display:flex;
    flex-wrap: wrap;

    .spellRow{
        width: 320px;
        border: 1px solid #a0a0a0;
        padding: 3px;
        margin: 3px;

        .spellTitle{
            font-family: Quintessential;
            color: #802010;
            font-size: 11pt;
        }
        
        .spellInfo{
            font-size: 8pt;
            margin:0;
    
            .label{
                font-weight: bold;
                display: inline-block;
                width: 60px;
            }
        }


        .spellAdd, .spellDel{
            display:flex;
            width: 100%;
            justify-content: space-between;  
            border-bottom: 1px solid #00000080;             

            span{
                cursor: pointer;
            }
        }
    }
}