.opponentGroup{

    .opponentGroupItem{
        display:flex;
        gap: 10px;
    }
}

.opponentDetail{
    position:absolute;
    top:80px;
    right: 198px;
    width: 380px;
    background-color: #f0f0f0f0;
    padding:5px;
    z-index: 1000;

    .opponentExtra{
        display:flex;
        
        input[type='number']{
            width: 32px;
        }
    }
}

.addNpcDialog{
    position:absolute;
    top:-80px;
    right: 198px;
    //width: 460px;
    //height: 400px;
    background-color: #f0f0f0f0;

    .addNpcList{
        display:flex;
        gap: 5px;
        width: 600px;
        flex-wrap: wrap;
    }
}
