@import '../variables.scss';          

.tile{
    width: 350px;
    height: 240px;
    border: 1px solid #{$border-color};
    background-color: #f0f0f0d0;
    border-radius: 5px;
    position: relative;
    margin:5px;
    display: flex;
    flex-direction: column;
}

.tileHeader{
    background-color: #{$header-bkr-color};
    font-size:12px;
    color: #{$header-color};
    margin:0;
    padding: 4px 6px;   
}

.tileContent{
    width:340px;
    margin:5px auto;
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;
}


.tile.clickable:hover{
    box-shadow: #00000080 5px 5px;
}