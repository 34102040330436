
.chooseWeapon{
    text-align: center;
    margin: 10px 0 0 0;
    border: 1px solid #00000080;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
}

.fightRanks{

    .weaponDamage{
        cursor: pointer;
        border: 1px solid #00000080;
        border-radius: 3px;        
    }
}