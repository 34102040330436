.rulesNavChildren{
    margin-left: 5px;
}


#loginLink, #logoffLink{
    color: rgba(255,255,255,0.66); /* #FFFFFFC0;*/
}



.menuIconSet{
    display: flex;
    align-items: stretch;
    align-content: flex-end;
    flex-wrap: wrap;    
    margin-right: 10px;
}

.rulesSearch{
    display:block;
    width:320px;
    position:fixed;
    left: 0;
    top:32px;
    bottom:0;
    background-color:#000000a0;
    overflow-y: scroll; 
    scrollbar-width: thin; 
    scrollbar-color: #e0e0e020 #a0a0a060;  
    color: #d0d0c0;  
}

.rulesNavContainer{
    display:block;
    width:180px;
    position:fixed;
    left: 0;
    top:32px;
    bottom:0;
    background-color:#000000a0;
    color: #d0d0c0;  
}

.rulesSearch, .rulesSearch p, .rulesSearch td, .rulesSearch h2, .rulesSearch th, 
    .rulesSearch table.spell .rulesSearch.title, .rulesSearch #options {
    color: #ada78c;
}

.meetingOptions{
    position:fixed;
    top: 32px;
    left:0;
    z-index: 10000;
    background-color:#000000a0;
    color:#f0f0f0;
    padding: 10px;
    border-bottom-right-radius: 10px;

    div,p,h5{
        color: #f0f0f0;
    }

    .deviceItem{
        cursor: pointer;
        padding-left: 20px;
    }

    .deviceItem.selectedDevice:before{
        content: '\F272';
    }
}