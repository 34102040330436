.initiativeButton{
    width: 24px;
    height:24px;
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
    border: 1px solid #00000080;
    border-radius:3px;
    cursor:pointer;
    opacity:0.6;
}


.initiativeButton:hover{
    opacity: 1;
}

.initiativeButton.initiativeButtonAgile{
    background-image: url(./assets/agile.svg);
}

.initiativeButton.initiativeButtonBrutal{
    background-image: url(./assets/brutal.svg);
}

.initiativeButton.initiativeButtonStealth{
    background-image: url(./assets/stealth.svg);
}

.initiativeButton.defensiveDisabled{
    background-image: url(./assets/defensiveDisabled.svg);
}

.initiativeButton.defensiveEnabled{
    background-image: url(./assets/defensiveEnabled.svg);
    opacity:1;
}

.initiativeButton.bonusButton{
    background-image:url(./assets/bonus.svg);
}

.initiativeButton.bonusInUseButton{
    background-image:url(./assets/bonusinuse.svg);
}

.initiativeButton.initiativeButtonUnaware{
    background-image: url(./assets/unaware.svg);
}

.initiative{

    .latestInitiative{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .initiativeButtons{
        display: flex;
        justify-content: space-between;

        >div{
            display:flex;
            gap: 3px;
        }
    }

    .initiativeGuide{
        display:flex;
        flex-direction: column;

        p{
            margin:0;
        }
    }

    .temporaryBonus{
        padding:5px 2px;

        .temporaryBonusField{
            display:flex;
            justify-content: space-between;
        }

        .temporaryBonusButtons{
            display: flex;
        }
    }
}