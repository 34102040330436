
.app-img-square{
    width:12rem;
}

.app-img-wide{
    width:12rem;
}

.app-img-flat{
    width:12rem;
    height:2.25rem;
}

.app-img-narrow{
    width:6.75rem;
}

.app-image-editor{
    display:flex;

    .app-cropper-holder{
        display:flex;
        justify-content:center;
    }
    
    .app-image-holder{
        padding: 0.4rem;
        border: 1px solid #00000080;

    }
    
}

.app-image-popup-body{
    display: flex;
    flex-direction: column;
}

.app-image-popup-body > *{
    flex:1;
    display: flex;
    padding: 0.2rem;
}

.app-cropper-image{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;}


.app-cropper-image img{
    width:100%;
    min-height: 2rem;
    background-color: #f0f0f0;
}

.app-cropper-image > div{
    flex-direction: column;
    flex: 1;
    display: flex;
    justify-content: center;
    text-align: center;
}

.imageSelectorFileList{
    display:flex;
    flex-wrap: wrap;
    gap:10px;

    p {
        cursor:pointer;
        padding:4px;
        background-color: #ffffff80;
        border: 1px solid #00000080;
        border-radius: 5px;
        margin: 0;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        
        span {
            margin-left:20px;
        }
    }
}

.imageEditorOptions{
    margin-top:10px;
}

.imageSelectorBackground{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #00000020;
    z-index: 20000;

    .imageSelector{
        position:absolute;
        margin: 0 auto;
        top: 120px;
        width:360px;
        left:50%;
        margin-left: -180px;
        background-color: white;
        border:1px solid #a0a0a0;
        padding: 10px;
        border-radius: 5px;
    }
}