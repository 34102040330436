.customField {
    border:none;
    background:none;
    outline: none;
    margin-right: 0.4em;
}

input.customField{
    border-bottom: 1px solid #00000080;
    background-color: #ffffff80;
}

.checkBox{
    display: inline-flex;
    gap: 4px;
}