.ranks{
    border-top: 1px solid #e0e0e0;
    width: 100%;
    padding:0;
    margin:0;   
    line-height: 8px; 
}

.ranks.largeRanks{
    line-height:unset;
}

.rankstitle{
    font-size: 7px;
}

.emptyrank,.filledrank{
    font-size: 7px;
    font-family: "bootstrap-icons";
    margin: 0 2px;

    .dimmed{
        opacity: 70%;;
    }
}


.emptyrank::before{
    content: '\F28A';
}

.filledrank::before{
    content: '\F287';
}

.largeRanks{
    .rankstitle,.emptyrank,.filledrank{
        font-size: unset;
    }
}
