.diceCanvas{
    position:fixed;
    left:10px;
    top:20px;
    pointer-events: none;
    z-index: 30000;
}

.diceButtons{
    position:fixed;
    top: 25px;
    right: 20px;
    width:fit-content;
    height:fit-content;
    z-index: 300002;
    background-color: #f0f0f0d8;
    border-radius: 5px;

    button{
      background-position: center;
      background-repeat: no-repeat;
      width: 36px;
      height:36px;
      display:inline-block;
      cursor:pointer;
    }
    button.buttonD4{
      background-image: url(./assets/d4.svg);
    }
    button.buttonD6{
      background-image: url(./assets/d6.svg);
    }
    button.buttonD8{
      background-image: url(./assets/d8.svg);
    }
    button.buttonD10{
      background-image: url(./assets/d10.svg);
    }
    button.buttonD12{
      background-image: url(./assets/d12.svg);
    }
    button.buttonD20{
      background-image: url(./assets/d20.svg);
    }
    button.button2D4-2{
      background-image: url(./assets/2d4-2.svg);
    }

    h2{
        border-bottom: 1px solid #a0a0a0;
        justify-content: space-between;
        padding: 1px 3px;
        display: flex;
        min-width: 120px;
        background-color: #20202080;
        color:#e0e0e0e0;

        span{
            cursor:pointer;
        }

        span:after{
            font-family: bootstrap-icons;
            content: '\F629';
        }
    }

    .diceResult{
        text-align: center;
        padding: 5px;

        .resultValue{
            font-weight: bold;
            color: #2020a0;
            padding: 3px 6px;
            border: 1px solid #2020a0;
            font-size: 12pt;            
        }
    }
}


#diceOpener{
  display: inline-block;
  color:white;
  cursor:pointer;
  border-color: #f0f0f080;
  border-radius: 2px;
  border-style: solid;
  padding: 2px;

  img{
    height: 16px;
    width: 16px;
    display:inline;
    position: relative;
    top:0;
  }
}

#dice-canvas {
  display:block;
  pointer-events: none;
}

@media (max-height: 700px) and (orientation: portrait){
  #dice-box{
    height: 240px;
  }
}

@media (max-width: 720px) and (orientation: portrait){
  #dice-box{
    width: 100%;
  }
}

@media (max-width: 720px) and (orientation: landscape){
  #dice-box{
    width: 220px;
  }
}

#dice-box canvas {
  width: 100%;
  height: 100%;
}

#roll {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  cursor: pointer;
}
