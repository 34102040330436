.chatList{
    width: 240px;

    .messageItem {
        width: 100%;
    }

    .messageItem p{
        width: 90%;
        padding: 5px;
        border: 1px solid black;
        border-radius: 5px;
        background-color:#f0a080;
    }

    .messageItem.messageFromMe {
    }

    .messageItem.messageFromMe p{
        background-color: #80a0f0;
        margin-left:16px;
    }

}

.newChatMessage{
    display:flex;
    margin: 5px 0;

    textarea{
        border: 1px solid #00000080;
        background-color: #ffffff80;
        outline: none;
        flex:1;
    }

    .sendButton{
        width: 48px;
        height: 48px;
        background-image: url(./assets/send.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90%;
        border: 1px solid #00000080;
        border-radius: 5px;
    }
}
