.characterWizard{
    padding: 10px;
    display: flex;

    .wizardFinalize{
        display:flex;
        gap: 20px;

        .finalizeForm{
            width: 240px;

            .formRow{
                display:flex;
                flex-direction: column;

                label{
                    display: inline-block;
                    width: 80px;
                }

                input,select{
                    max-width: 120px;
                }
            }

            .rankstitle{
                display: inline-block;
                width: 60px;
            }
        }
    }

    >div{
        margin: 0 auto;
        max-width: 800px;
    }

    .content{
        background-color: #f0f0f0e0;
        width: fit-content;
        padding: 20px 30px;
        border: 1px solid #00000080;
        border-radius: 5px;
        margin:0 auto;        
    }

    .formRow{
        display: flex;
        gap: 10px;
        margin: 10px;
    }

    .formField{
        display: flex;
        gap:5px
    }
/*
    .rankstitle{
        display: inline-block;
        width: 60px;
    }
*/

}