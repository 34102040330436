.skills{

    .skill{
        margin-bottom: 10px;
        width: 120px;
        border: 1px solid #808080;
        border-radius: 5px;
        display: inline-block;
        margin: 5px;

        div{
            text-align: center;
        }

        .skillvalue{
            font-weight: bold;
        }
    }
}

.encumberedNote{
    p{
        color: #a02020;
    }
    .dropPackings{
        cursor:pointer;
        border: 1px solid #00000080;
        border-radius: 3px;
        margin-left:5px;
        color: #00000080;
    }
}