
.avatarList{
    display: flex;
    flex-wrap: wrap;
    width: 320px;
    position: absolute;
    margin-top: -160px;
    margin-left: 52px;
    z-index: 1000;

    .avatarButton{

    }
}