html.singleTOC main {
    display:block;
} 

.articleSubNav{
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: #ffffff80;
    padding: 10px 20px;
    width: fit-content;
    margin: 0 auto;
    border-radius: 5px;
    font-size: 1.2em;

    a{
        color: #204080;
        font-size: 1.2em;
    }

    a.articleParent{
        color:#4060a0;
        font-size: 0.9em;
    }
}

.rulesSpellList{
    display:flex;
}
#articleContainer{
    padding-top: 24px;
}

.flexArticle{
    display:flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .spell{
        width: 320px;
    }
}