.mapLayers{
    height: 400px;
    overflow-y: scroll;

    h6{
        font-family: "Quintessential";
        text-align: left;
    }

    .layerObject{
        cursor:pointer;

        .layerAsset{
            padding-left: 10px;
        }
    }
}