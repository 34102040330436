.characterCombat{
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .combatContent{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 200px;

        p{
            margin:0;
        }
    }

    .fight,.defence,.titles,.weapon {
        display:flex;
        justify-content: space-between;
    }

    .fight, .defence, .weapon{
        margin-top: 3px;
    }

    .fightRanks,.defenceAc{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        p{
            margin:0;
        }
    }

    .combatValue,.initiativeValue{
        display:flex;
        border: 1px solid #00000060;
        border-radius: 3px;
        width: 32px;
        justify-content: center;
        align-items: center;

        p{
            font-size: 2em;
            margin:0;
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-content: center;
            text-align: center;
        }
    }

    .combatValue.dimmed, .inactiveCombatValue{
        opacity: 50%;
    }

    .fightTitle, .defenceTitle{
        font-size: 1.2em;
    }

    .fightAndDefence{

        >div{
            margin-bottom: 5px;
        }
    }

    .hitpointsAndDamage, .fightDefence, .initiative{
        border: 1px solid #00000080;
        border-radius: 5px;;
        padding: 5px;
    }

    .hpDice{
        display: inline-flex;
        gap: 5px;
        cursor: pointer;
        border: 1px solid #00000080;
        border-radius: 3px;
        padding: 3px;
        
        img{
            height: 16px;
            width: 16px;            
        }
    }

    .hitpointsAndDamage .current{
        display:flex;
        flex-direction: column;

        >div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            align-items: center;
        }
    }

    .hitpointsAndDamage .modify {
        display: flex;
        flex-direction: column;

        >div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }
    }

    .heal{
        display:flex;
        align-items: center;

        label{
            margin-right: 20px;
        }

        p{
            display:flex;
            gap:5px;
        }

        .healButton{
            display: inline-block;
            width: 24px;
            height:24px;
            cursor: pointer;
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
            border: 1px solid #00000080;
            border-radius: 5px;
        }

        .heal1{
            background-image: url(../assets/heal1.svg);
        }

        .healD4{
            background-image: url(../assets/heald4.svg);
        }

        .healD8{
            background-image: url(../assets/heald8.svg);
        }

    }

    .damageTaken{
        p{
            display:flex;
        }
        input{
            margin: 0;
            background-color: #ffffff60;
            border: 1px solid #00000080;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }
    .addDamageButton{
        display: inline-block;
        width: 42px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #00000080;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-image: url(../assets/adddamage.svg);
    }

}