
.playersMain{

    .tileContainer{
        display:flex;

        .playerItem,.mapArea,.handoutItem,.journalTile, .naviButton{
            background-color: #f0f0f0;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid black;
            background-repeat: no-repeat;
            background-size: cover;
            width: 80px;
            height: 80px;
            position: relative;
            margin: 3px;
    
            .characterName{
                position:absolute;
                top:0;
                left:3px;
                font-family: 'Quintessential';
            }

            .playerName{
                position:absolute;
                bottom:0;
                left:3px;
                margin:0;
                font-size: 8pt;
            }

            img{
                position: absolute;
                width: 36px;
                bottom:3px;
                right:3px;
            }
        }

        .naviButton{
        }

        .naviButton.naviJournal, .naviButton.naviEncounter{
            background-size: 95%;
            background-position: center bottom ;

        }
/*
        .naviButton p{
            position:absolute;
            top:0;
            left:3px;
            font-family: 'Quintessential';

        }
*/
        .journalTile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            p {
                cursor:pointer;
                border: 1px solid #00000080;
                border-radius: 3px;
                padding: 5px;
            }
        }
    }

}

.ruleEnhancements .tileContent{
    overflow-y: scroll;
}