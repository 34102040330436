.mapEditor {
   /*width: 100%;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    margin: 0 auto;
    align-items: center;

    .mapHeading{
        display:flex;
        align-items: flex-end;
    }

    .mapTools{
        margin: 10px;
        position: absolute;
        left: 10px;
        /* right: 0; */
        top: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 2000;
        padding: 10px;
        border: 1px solid #00000080;
        border-radius: 5px;
    }


    .imageButton{
        display: inline-block;
        width:48px;
        height:48px;;

    }

    .formInput{
    
        label {
            display: block;
        }
    }

    .rangeInput input{
        flex-grow: 1;
    }
    .decoratorList{
        display: flex;
        gap:5px;
        flex-wrap: wrap;
        max-width: 180px;
    }
}

.mapHolder{
    display:flex;
}

.mapHolder.fullscreenMap #designMap{
    position:fixed;
    top: 200px;
    bottom: 40px;
    overflow: scroll;
    left: 0;
    right: 0;
    text-align: center;
}

.mapArea{
    position:relative;
    margin: 10px;
    flex:1 1 auto;
    overflow: scroll;
    scrollbar-color: #a0a0a0f0 #e0e0e020;

    canvas.withBackground{
        background-color: #808080;
    }

    input.horizontal{
        position:absolute;
        left:0;
        bottom:0;
        width:320px;
    }

    input.vertical{
        position:absolute;
        left: 170px;
        top: 150px;
        width: 320px;
        transform: rotate(90deg);
    }
}

main.playerMap .mapArea{
    margin:0;
}

.mapContainer{
    margin: 0 auto;
/*    display: flex; */
}

.showNote{
    position:absolute;
    background-color: #ffffff80;
    border: 1px solid #00000080;
    border-radius: 5px;
    padding: 5px;
    z-index: 1000;

    .noteHeader{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border-bottom: 1px solid #00000080;
    }
}

.mapPointerNote{
    position: absolute;
    z-index: 1000;
    border:1px solid #00000080;
    border-radius: 5px;
    padding: 5px;
    //top: 160px;
    background-color: #faebd7;

    p{
        margin:0;
        padding:0;
        font-size: 8pt;
    }
}
