.wizardSearch{
    width: 360px;
    padding: 5px;
    background-color: #ffffff80;
    border-radius: 5px;
}


.wizardSearch h2 span{
    display:none;
}