.allSpells{
    display:flex;
    flex-wrap: wrap;;
    gap: 10px;
}

.ownSpells{
    display:flex;
    flex-wrap: wrap;
    gap: 10px;

    .spellHeader.spellIsCast{
        opacity: 0.5;

        .label{
            font-weight: normal;
        }
    }
    
    .spellContainer{
        width: 340px;
        border: 1px solid #000000a0;
        border-radius: 5px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

.spellItem{
    display:block;
    width: 320px;
    padding:3px;
    border: 3px;
    border: 1px solid #00000080;
    background-color: #ffffff20;

    .spellHeader{
        
        h5{
            display: flex;
            justify-content: space-between;            
        }

        p{
            display:flex;
            margin:0;
            padding:0;
            justify-content: space-between;

            .label{
                font-weight: bold;
            }

        }
    }

    .spellInfo{
        display:flex;
        margin:0;
        padding:0;

        .label{
            width:60px;
        }
    }

    .spellDescription{
        border-top: 1px solid #00000040;
        padding-top: 5px;
    }
}