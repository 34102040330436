.colorButtons{
    display:flex;
    gap:2px;
}

.colorButton{
    display:inline-flex;
    flex-direction: column;
    width: 32px;
    /*
    border: 1px solid #00000080;
    border-radius: 3px;
    */
    align-items: center;
    justify-content: center;    

    .colorSample {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: 1px solid #00000050;
        background-color: white;
        display:flex;
        align-items: center;
        justify-content: center;
        
        
        div {
            width: 16px;
            height: 16px;
        }
    }
}

.customColorButton{
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    >input{
        width: 24px;
        border: 1px solid #00000050;
        cursor: pointer;
    }
}
