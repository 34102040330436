.fatigue{
    position:relative;
    display:flex;
    align-items: center;
    margin: 5px 0;
}

.addMarkers{
    position:absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #a0a0a0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .markerWithTitle{
        cursor: pointer;
        display: flex;
        gap: 3px;
        align-items: center;
    }
    
    .markerWithTitle:hover{
        background-color: #f0f0f0;
    }

}

.markers{
    display: flex;
    gap: 3px;
}

.fatigueMarker{
    width: 24px;
    height:24px;
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
    border: 1px solid #00000080;
    border-radius:3px;
    cursor:pointer;
    opacity:0.6;
}

.fatigueMarkerX{
    background-image:url(./assets/exhaustion.svg);
}

.fatigueMarkerH{
    background-image:url(./assets/hunger.svg);
}

.fatigueMarkerM{
    background-image:url(./assets/mental.svg);
}

.fatigueMarkerW{
    background-image:url(./assets/warm.svg);
}

.fatigueMarkerC{
    background-image:url(./assets/cold.svg);
}

.fatigueMarkerS{
    background-image:url(./assets/sleep.svg);
}


.fatiguePenalty, .addFatigue{
    margin: 0 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12pt;
}

.characterMovementNote{
    p{
        color: #a02020;
    }
}