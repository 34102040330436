
/*
.meeting{
    position: absolute;
    top: 32px;

}

#videocontainer{
    display:flex;
    align-items: center;
    gap: 5px;
    max-height: 80vh;
    position: absolute;
    top:36px;
    width:220px;


    video{
        height: 100px;
        flex: 1 1 0;
    }
}
*/