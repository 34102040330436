.lineOptions{

    .lineBasicOptions{
        display: flex;
        gap: 10px;
    }

    button{
        width: 28px;
        height: 28px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        background-size: 20px;
        position:relative;
        opacity: 0.5;
    }

    button.selectedTool{
        opacity:1;
    }

    .buttonDrawArea{
        background-image: url(../images/draw-area.svg);
    }

    .buttonDrawLine{
        background-image: url(../images/draw-line.svg);
    }
    
    .buttonLineSolid{
        background-image: url(../images/line-solid.svg);
    }

    .buttonLineCurve{
        background-image: url(../images/line-curve.svg);
    }


    .buttonLineFence{
        background-image: url(../images/line-fence.svg);
    }

    .buttonLineFortification{
        background-image: url(../images/line-fortification.svg);
    }

    .buttonLineLog{
        background-image: url(../images/log-sample.svg);
    }

    .buttonLineStone{
        background-image: url(../images/stone-sample.svg);
    }

    .buttonVisible{
        background-image: url(../images/visible.svg);
    }

    .buttonSetSelected{
        background-image: url(../images/set-selected.svg);
    }
}