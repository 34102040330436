.characterSheet{
    margin: 10px;

    h3 {
        border-bottom:1px solid #00000080;

        span.app-bi-icon.first{
            margin-left: auto;
        }
    }

}

.characterSheetPage{
    width: 800px;
    margin: 0 auto;
}

.nameEtAll{
    border: 1px solid #00000080;
    border-radius: 5px;
    padding: 5px;   
    margin: 5px; 
}

.characterSheetNavi{
    display:flex;
    display: flex;
    /* justify-content: center; */
    width: 800px;
    margin: 0 auto;
    align-items: flex-end;
}

.characterSheetNavi h2{
    font-size: 16pt;
    margin-right: 10px;
}

.characterSheetNavi a {
    font-size: 12pt;
    margin: 4px 10px 4px 0;
}

.containerContainer{
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
}


.characterNotesAndLanguages{
    display:flex;
    gap: 20px;
}

.characterLanguages{
    width: 240px;

    .languagesContainer{
        display: flex;
        width: 100%;
        gap: 10px;
        flex-wrap: nowrap;
        justify-content: space-between;

        .notKnown{
            text-align: right;
        }

    }

}

.characterNotes{
    .notesContainer{

        display:flex;
        flex-wrap: wrap;
        gap: 10px;

        .notesItem{
            display: flex;
            flex-direction: column;

            textarea{
                height: 120px;
            }

            .iconButtons{
                font-size:1.5em;
                display: flex;

                .app-icon-delete{
                    margin-left: auto;
                }
            }
        }
    }
}