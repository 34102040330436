.assetGroup{
    max-width: 320px;    

    .assetGroupList{
        display: flex;
        flex-wrap: wrap;
    }
}

.assetOptions{

    .inputRow{
        display:flex;
        justify-content: space-between;

        input, textarea, select{
            width: 132px;
        }
    }
}

.selectedAssetType{
    display:flex;
}

.sampleLora{
    font-family: "Lora";
}

.sampleQuintessential{
    font-family:"Quintessential"
}

.sampleCalligraffitti{
    font-family:"Calligraffitti";
}

.sampleUnifrakturMaguntia{
    font-family: "UnifrakturMaguntia";
}

.sampleUnifrakturCook{
    font-family: "UnifrakturCook";
    font-weight: 700;
}

.sampleRockSalt{
    font-family: "Rock Salt";
}

.sampleImperialScript{
    font-family: "Imperial Script";
}

.sampleRobotoSlab{
    font-family: "Roboto Slab";
}