.characterWizardTile .tileContent{
    background-image: url(./assets/wizard.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;

    display: flex;
    flex-direction: column;

    p{
        width: 70%;
    }

}

.characterTileContent{
    display: flex;
    flex-direction: column;
    background-position: center bottom;
    background-size: 30%;
    background-repeat: no-repeat;
    p{
        text-align: center;
    }
}


.otherCharacterDetails{
    display:flex;
    gap:20px;
    flex-wrap: wrap;
}