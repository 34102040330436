.attributes{

    .attributeContainer{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;

        >div{
           display: flex;
           flex-direction: column;
        }
    }

    .attribute{
        display:flex;
        flex-direction: row;
        width: 100px;
        justify-content: space-between;
        border: 1px solid #808080;
        border-radius: 5px;
        margin: 5px;
    }

    .attribute p{
        margin:0;
        padding: 3px;
    }
}