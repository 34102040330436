.characterSheetBasics{
    display: flex;
    gap: 10px;

    .characterImage{
        margin-top: 5px;
        border-radius: 10px;
        overflow: hidden;
        
        img{
            width: 220px;
        }
    }

    .characterExtra{
        display:flex;
        justify-content: space-between;

        .extraText{
            flex:1;
        }

        img{
            width: 36px;
        }
    }

    .skills{
        flex: 1;
        min-width: 320px ;
    }
}

