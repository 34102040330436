
/*
main{
    position: absolute;
    left:0;
    right:0;
    top:56px;
    bottom:28px;
    overflow-y: scroll;
    display:flex;
}

header{
    position:absolute;
    top:0;
    left:0;
    right:0;
    height: 28px;
    border-bottom: 1px solid #a0a0a0;
}

header h1{
    font-size:18px;
    margin:2px;
}

footer {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height: 28px;
    border-top: 1px solid #a0a0a0;
}

nav{
    display:flex;
    position:absolute;
    top:28px;
    bottom: 56px;
    left:0;
    right:0;
}
*/    
@media (orientation: landscape) {
    main {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
    
@media (orientation: portrait) {
    main {
        flex-direction: column;
    }

}

.tileContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.subNavContent{
    padding: 10px;
}
