
.encounterOptions{
    border: 1px solid #00000080;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.encounterView{
    display:flex;
    gap: 10px;

    .characterList,.opponentList{
        border: 1px solid #00000080;
        border-radius: 10px;
        padding: 10px;
        position: relative;
    }

    .encounterMap{
        border: 1px solid #00000080;
        border-radius: 10px;
        padding: 10px;
    }

}

.encounterDetail{
    width: fit-content;
    margin: 0 auto;
}

.encounterDetail.noEncounter{
    max-width: 800px;
    margin: 0 auto;
    
    .encounterView>p{
        border:1px solid #00000080;
        background-color: #ffffff80;
        border-radius: 5px;
        padding: 20px;
        font-size:20px;
    }
}

.addNpcDialog{
    position:absolute;
    top:-80px;
    right: 198px;
    //width: 460px;
    //height: 400px;
    background-color: #f0f0f0f0;

    .addNpcList{
        display:flex;
        gap: 5px;
        width: 600px;
        flex-wrap: wrap;
    }
}


.roleMaster .npcButton{
    cursor:default;
}
